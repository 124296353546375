<template>
  <NotPermission v-if="!authenticated" />

  <v-container v-else fluid grid-list-lg>
    <Breadcrumbs
      :routes="[{ name: 'Inicio' }, { name: 'Producto' }, { name: 'Listado de items' }]"
    />
    <div v-if="loading" class="text-xs-center" style="padding:15px;">
      <v-progress-circular :size="50" color="primary" indeterminate></v-progress-circular>
    </div>
    <v-card v-else style="border-radius:10px 10px 0 0;">
      <v-toolbar card style="border-radius:10px 10px 0 0;" color="primary" dark>
        <v-toolbar-title>{{ this.currentProducto.nombre }}  (Cat: {{ this.currentProducto.categoria.nombre }})</v-toolbar-title>
      </v-toolbar>
      <br />
      <v-toolbar color="grey darken-1" dark card>
        <v-toolbar-title>Listado de Items</v-toolbar-title>
        <v-spacer />
        <v-btn :to="{ name: 'CrearItems', params: { id: $route.params.id } }" color="primary">
          <svg style="width:24px;height:24px; margin-right:10px;" viewBox="0 0 24 24">
              <path fill="currentColor" d="M20 14H14V20H10V14H4V10H10V4H14V10H20V14Z" />
          </svg>
          Agregar Item
        </v-btn>
      </v-toolbar>
      <v-container fluid grid-list-lg>
        <v-flex v-if="items.length" sm6>
          <v-text-field
            v-model="searchItems"
            box
            append-icon="search"
            label="Buscar"            
            clearable
            hide-details
            style="margin-bottom:25px;"
          />
        </v-flex>
        <v-data-table
          :headers="[
            { text: 'ID', value: 'id' },
            { text: 'SKU', value: 'SKU' },
            { text: 'Nombre', value: 'nombre' },            
            { text: 'Precio', value: 'precio_bruto' },
            { text: 'Descuento', value: 'descuento' },
            { text: 'Precio Desc', value: 'precio_con_descuento' },
            { text: 'Costo', value: 'costo' },
            { text: 'Dimensiones'},
            { text: 'Estado', value: 'estado' },
            { text: 'Acciones' },
            { text: 'Borrar' }
          ]"
          :items="items"
          :loading="loadingItems"
          :search="searchItems"
          :rows-per-page-items="[10, 25, 35, 50]"
          class="elevation-1"
        >
          <tr slot="items" slot-scope="props">
            <td class="px-3">
              {{ props.item.id }}
            </td>
            <td class="px-3">
              {{ props.item.SKU }}
            </td>
            <td class="px-3">
              {{ props.item.nombre }}
            </td>
            <td class="text-xs-center px-3">
              $ {{ formatMoney(props.item.precio_bruto) }}
            </td>
            <td class="text-xs-center px-3">
              {{ props.item.descuento }}%
            </td>
            <td class="text-xs-center px-3">
              {{ props.item.precio_con_descuento ? '$ '+formatMoney(props.item.precio_con_descuento) : '' }}
            </td>
            <td class="text-xs-center px-3">
              {{ props.item.costo ? '$ '+ formatMoney(props.item.costo) : '' }}
            </td>
             <td class="text-xs-center px-3" v-if="props.item.dimension && props.item.dimension.alto && props.item.dimension.ancho && props.item.dimension.largo && props.item.dimension.peso">
               <v-icon color="green">
                        check_circle
                </v-icon>
            </td>
            <td class="text-xs-center px-3" v-else>
              <v-icon color="pink">
                        close
                </v-icon>
            </td>
            <td class="px-3">
              <v-chip v-if="props.item.estado === true" small color="primary" text-color="white">
                ACTIVO
              </v-chip>
              <v-chip v-else-if="props.item.estado === false" small>
                INACTIVO
              </v-chip>
            </td>
            <td class="px-3">
              <v-btn
                class="ma-0"
                :to="{ name: 'EditItem', params: { id: props.item.id } }"
                small
                color="info"
              >
                Editar
              </v-btn>
             
            </td>
            <td class="px-3">
             <v-btn
                v-if="userIsAdmin || userIsSupervisor"
                class="ma-0"
                small
                icon
                flat
                @click="openModalDeleteProductoItem(props.item)"
                color="error"
              >
                <v-icon small>
                  delete
                </v-icon>
              </v-btn>
            </td>
          </tr>
        </v-data-table>
      </v-container>
      <!-- <center>
        <v-btn color="error" @click="$router.push({ name: 'ListaProducto' })">
          Volver
        </v-btn>
      </center> -->
    </v-card>
    <ModalDeleteItem @onChange="getData" />
  </v-container>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";

export default {
  metaInfo() {
    return { title: "Listado de items" };
  },

  components: {
    NotPermission: () => import("@/views/errors/NotPermission"),
    Breadcrumbs: () => import("@/components/Breadcrumbs"),
    ModalDeleteItem: () => import("@/views/productos/ModalDeleteItem")
  },
  data() {
    return {
      loading: false,
      activeBtn: 5,
      avatar: "",
      searchItems: ""
    };
  },

  computed: {
    ...mapState({
      items: state => state.productos.items,
      loadingItems: state => state.productos.loadingItems,
      currentProducto: state => state.productos.currentProducto
    }),
    ...mapGetters({
      authenticated: "auth/check",
      user: "auth/user",
      userIsAdmin: "auth/userIsAdmin",
      userIsSupervisor: "auth/userIsSupervisor"
    })
  },
  created() {
    this.loading = true;
    this.getItems({ productoId: this.$route.params.id });
    this.getProducto({ productoId: this.$route.params.id });
    this.loading = false;
  },
  methods: {
    ...mapActions({
      getItems: "productos/getItems",
      getProducto: "productos/getProducto",
      replaceShowModalDeleteProductoItem: "productos/replaceShowModalDeleteProductoItem",
      replaceCurrentProductoItem: "productos/replaceCurrentProductoItem"
    }),
    getData() {
      this.getItems({ productoId: this.$route.params.id });
    },
    openModalDeleteProductoItem(productoItem) {
      this.replaceCurrentProductoItem({ productoItem });
      this.replaceShowModalDeleteProductoItem({ status: true });
    },
    formatMoney(numero) {
            return new Intl.NumberFormat(["ban", "id"]).format(numero);
        },
  }
};
</script>
